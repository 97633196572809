import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { useAppStateContext } from 'src/appState/AppState';

export const BonusEngineFreeBetErrorMessages = observer('BonusEngineFreeBetErrorMessages', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const { bonusEngineFreeBetErrors } = betSlipState.basicBetSlipState;

    return (
        <>
            {bonusEngineFreeBetErrors.map((error) => {
                switch (error.field) {
                    case 'potentialReturn': {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const debugDetails = error.debugDetails as string;
                        // debug value for this error looks like: BetMinOdds [0.333 GTE 1.5]
                        const numericValues = debugDetails.match(/-?\d+(\.\d+)?/g);

                        const minOdds = numericValues === null ? 0 : parseFloat(numericValues[1] ?? '0');

                        return (
                            <Messages
                                type='error'
                                message={
                                    <I18n
                                        langKey='betslip.free-bets.minimum-odds-message'
                                        defaultText='Minimum Odds on free bets are {minOdds}'
                                        params={{ minOdds }}
                                    />
                                }
                            />
                        );
                    }
                    case 'selectedFreeBets': {
                        return (
                            <Messages
                                type='error'
                                message={
                                    <I18n
                                        langKey='betslip.free-bets.stake-exceeded-message'
                                        defaultText='Lower your stake if you want to use your bet credits.'
                                    />
                                }
                            />
                        );
                    }
                    case 'bets[].freeBets':
                        return (
                            <Messages
                                type='error'
                                message={
                                    <I18n
                                        langKey='betslip.free-bets.bonus-exceeded-message'
                                        defaultText="Added free bets can't cover all bets total stakes"
                                    />
                                }
                            />
                        );
                    default:
                        return (
                            <Messages
                                type='error'
                                message={error.code}
                            />
                        );
                }
            })}
        </>
    );
});
