import { getPossibleBetsNew } from 'src/domains/sportsbook/betting/betting/getPossibleBets';
import { computed, makeObservable } from 'mobx';
import { Resource } from 'src_common/common/mobx-utils/Resource';
import * as uuid from 'uuid';
import { RabState } from 'src/domains/sportsbook/betting/state/rabState/RabState';
import { CombinationsType, LegType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipTypes';
import { BetSlipUserDataType } from 'src/domains/sportsbook/betting/betSlipState/BetSlipSheredTypes';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { TypeId } from 'src_common/common/websocket2/type';
import { Amount } from 'src_common/common/amount/Amount';
import { sortSelectionByIndex } from 'src/domains/sportsbook/betting/betSlipState/possibleBetsState/PossibleBetsState.utils';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { ChannelType } from 'src/domains/sportsbook/betting/betting/types';
import { BetslipStateLegsType } from 'src/domains/sportsbook/betting/state/betSlipState/LegStateType';
import { BetslipData } from 'src/domains/sportsbook/betting/betSlipState/BetslipData';
import { RequestDebounce } from './debounceRequest';
import {
    ParsedPossibleBetsType,
    BettingPossibleBetsType,
    LegCombinationsForRequestType,
    PlayableBalanceAmountsType,
    BetResponseExtendedType,
    BetResponseType,
    ParsedCombinationsType,
    ErrorRawType,
    AccountDataType,
    GetPossibleBetsRequestNewType,
    BetsForRequestType,
    SmallLegStrictWithEachWayType,
    RabRawType,
} from 'src/domains/sportsbook/betting/betting/getPossibleBetsTypes';
import { Common } from 'src/domains/common/Common';
import { SelectedBonusType } from 'src/domains/sportsbook/betting/state/BetSlipState';

export class PossibleBetsRequestState {
    private readonly rabState: RabState | null;
    private readonly lifeSpanState: LifeSpanState; // TODO - Lifespan should use EventEmmiter

    public prevCorePossibleBetsResponse: ParsedPossibleBetsType | null;

    private requestDebounce: RequestDebounce;
    private configComponents: ConfigComponents;

    public constructor(
        private readonly sdkCustomer: SdkCustomer,
        private readonly getAccountData: () => BetSlipUserDataType,
        rabState: RabState | null,
        lifeSpanState: LifeSpanState,
        private readonly getChannel: () => ChannelType,
        private readonly betslipData: BetslipData,
        private readonly common: Common,
        private readonly selectedBonuses: Map<string, Array<SelectedBonusType>>
    ) {
        makeObservable(this);
        this.configComponents = ConfigComponents.get(this.common);
        this.prevCorePossibleBetsResponse = null;
        this.rabState = rabState;
        this.lifeSpanState = lifeSpanState;
        this.requestDebounce = new RequestDebounce(500);
    }

    @computed public get coreLegsPossibleBetsRequest(): Array<BetslipStateLegsType> {
        const mapCorePrepareLegs = this.betslipData.getMapCorePrepareLegsValue();
        return sortSelectionByIndex(Array.from(mapCorePrepareLegs.values()));
    }

    @computed private get justPossibleBetsResponse(): Resource<BettingPossibleBetsType> {
        const requestDataNew = this.corePossibleBetsRequestNew;
        return new Resource(async (): Promise<BettingPossibleBetsType> => {
            return this.requestDebounce.call(() => getPossibleBetsNew(requestDataNew));
        });
    }

    @computed public get corePossibleBetsResponse(): ParsedPossibleBetsType | null {
        const rabBets = this.corePossibleBetsRequestNew.rabBets ?? [];

        if (this.corePossibleBetsRequestNew.bets.length === 0 && rabBets.length === 0) {
            return this.prevCorePossibleBetsResponse;
        }

        const corePossibleBetsResponse = this.justPossibleBetsResponse.get();

        if (corePossibleBetsResponse.type === 'ready') {
            const response = corePossibleBetsResponse.value;
            if (response !== null && response.status === 'error') {
                if (this.prevCorePossibleBetsResponse !== null) {
                    const error: ErrorRawType = {
                        code: 'INTERNAL_SERVER_ERROR_MESSAGE',
                        debugDetails: 'Server error',
                        details: null,
                        field: null,
                        pointer: null,
                        resource: 'Server',
                        leg: null,
                    };

                    const response = {
                        ...this.prevCorePossibleBetsResponse,
                        errors: [error],
                    };

                    return response;
                }
                return this.prevCorePossibleBetsResponse;
            }
            if (response?.status === 'success') {
                this.prevCorePossibleBetsResponse = response.data;

                //// LIFESPAN
                // TODO - Lifespan should use EventEmmiter
                if (this.configComponents.config.lifeSpanActive) {
                    this.lifeSpanState.isSelectionsHasBoost(this.prevCorePossibleBetsResponse.bets);
                }

                return response.data;
            }
        }
        return this.prevCorePossibleBetsResponse;
    }

    @computed public get coreRabPossibleBetsResponse(): Array<RabRawType> {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.rabBets ?? [];
        }
        return [];
    }

    @computed public get coreLegsPossibleBetsResponse(): Array<BetResponseType> {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.bets;
        }
        return [];
    }

    @computed public get parsedLegsPossibleBetsResponse(): Array<LegType> {
        const preparedLegs: Array<LegType> = [];
        for (const leg of this.coreLegsPossibleBetsResponse) {
            const selectionId = parseInt(leg.id, 10);
            const selectionModel = isNaN(selectionId) ? null : this.sdkCustomer.models.getSelection(selectionId);

            if (selectionModel !== null && leg.type === 'SGL') {
                const firstLeg = leg.legs[0];

                preparedLegs.push({
                    eachWay:
                        typeof selectionModel.templateMarketId === 'string' &&
                        selectionModel.templateMarketId.includes('each-way-extra') //TODO: replace the name with extra-places when its changed on the backend side
                            ? true
                            : leg.eachWay,
                    errors: leg.errors,
                    eventId: selectionModel.eventId,
                    freeBets: leg.freeBets,
                    freebetCredits: leg.freebetCredits,
                    freebetRemarks: leg.freebetRemarks,
                    marketId: TypeId.getMarketId(selectionModel.marketId),
                    maxStake: leg.maxStake ?? null,
                    potentialReturns: leg.potentialReturns ?? '0',
                    potentialReturnsAt: leg.potentialReturnsEw ?? '0',
                    potentialReturnsEw: leg.potentialReturnsEw ?? '0',
                    price: selectionModel.price ?? null,
                    priceType: firstLeg === undefined ? 'fp' : firstLeg.priceType ?? 'fp',
                    related: this.marketsWithRelatedSelections.includes(selectionModel.marketId),
                    selectionId: selectionModel.id,
                    index: selectionModel.id,
                    stakePerLine: leg.stakePerLine,
                    timestamp: 0,
                    numLines: 1,
                    totalStake: leg.totalStake,
                    uuid: selectionModel.uuid,
                });
            }
        }

        return preparedLegs;
    }

    @computed public get legsPossibleBetsResponseMap(): Map<number, LegType> {
        const legsMap: Map<number, LegType> = new Map();
        for (const leg of this.parsedLegsPossibleBetsResponse) {
            if (leg.selectionId !== null) {
                legsMap.set(leg.selectionId, leg);
            }
        }

        return legsMap;
    }

    @computed public get coreCombinationsPossibleBetsResponse(): Record<string, ParsedCombinationsType> | null {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.combinations;
        }
        return null;
    }

    // TODO - only for BetslipSpecialMessageComponent - to refactor
    @computed public get stakeOneCombinationsPossibleBetsResponse(): Record<string, ParsedCombinationsType> | null {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.stakeOneCombinations;
        }
        return null;
    }

    @computed public get stakeOneCombinationsForViewMap(): Map<string, CombinationsType> {
        const tempMap: Map<string, CombinationsType> = new Map();
        if (this.stakeOneCombinationsPossibleBetsResponse !== null) {
            for (const combination of Object.values(this.stakeOneCombinationsPossibleBetsResponse)) {
                const cast = this.castCompetitions.get(combination.type);
                const simpleCombinationModel = {
                    errors: combination.errors ?? null,
                    eachWay: false,
                    ewOffered: combination.ewOffered,
                    freeBets: combination.freeBets ?? [],
                    freebetCredits: combination.freebetCredits ?? [],
                    freebetRemarks: combination.freebetRemarks ?? [],
                    legs: combination.legs,
                    maxStake: combination.maxStake ?? null,
                    name: combination.name,
                    numLines: combination.numLines,
                    potentialReturns: combination.potentialReturns,
                    potentialReturnsAt: combination.potentialReturnsAt ?? null,
                    potentialReturnsEw: combination.potentialReturnsEw ?? null,
                    price: combination.price ?? null,
                    stakePerLine: new Amount('1').value,
                    totalStake: new Amount('1').value,
                    type: combination.type,
                };

                const castCombinationModel =
                    cast === undefined
                        ? {}
                        : {
                              errors: cast.errors,
                              eachWay: cast.eachWay,
                              freeBets: cast.freeBets,
                              freebetCredits: cast.freebetCredits,
                              freebetRemarks: cast.freebetRemarks,
                              legs: cast.legs,
                              maxStake: cast.maxStake ?? null,
                              price: cast.price,
                              type: cast.type,
                          };

                const combinationModel: CombinationsType = {
                    ...simpleCombinationModel,
                    ...castCombinationModel,
                };

                tempMap.set(combination.type, combinationModel);
            }
        }

        return tempMap;
    }

    @computed private get castCompetitions(): Map<string, BetResponseType> {
        const tempMap: Map<string, BetResponseType> = new Map();
        for (const cast of this.coreLegsPossibleBetsResponse) {
            if (cast.type !== 'SGL') {
                tempMap.set(cast.type, cast);
            }
        }
        return tempMap;
    }

    @computed public get combinationsForViewMap(): Map<string, CombinationsType> {
        const tempMap: Map<string, CombinationsType> = new Map();
        if (this.coreCombinationsPossibleBetsResponse !== null) {
            for (const combination of Object.values(this.coreCombinationsPossibleBetsResponse)) {
                const cast = this.castCompetitions.get(combination.type);

                const simpleCombinationModel = {
                    errors: combination.errors ?? null,
                    eachWay: false,
                    ewOffered: combination.ewOffered,
                    freeBets: combination.freeBets ?? [],
                    freebetCredits: combination.freebetCredits ?? [],
                    freebetRemarks: combination.freebetRemarks ?? [],
                    legs: combination.legs,
                    maxStake: combination.maxStake ?? null,
                    name: combination.name,
                    numLines: combination.numLines,
                    potentialReturns: combination.potentialReturns,
                    potentialReturnsAt: combination.potentialReturnsAt ?? null,
                    potentialReturnsEw: combination.potentialReturnsEw ?? null,
                    price: combination.price,
                    stakePerLine: '0',
                    totalStake: '0',
                    type: combination.type,
                };

                const castCombinationModel =
                    cast === undefined
                        ? {}
                        : {
                              errors: cast.errors,
                              eachWay: cast.eachWay,
                              freeBets: cast.freeBets,
                              freebetCredits: cast.freebetCredits,
                              freebetRemarks: cast.freebetRemarks,
                              legs: cast.legs,
                              maxStake: cast.maxStake ?? null,
                              potentialReturns: cast.potentialReturns ?? null,
                              potentialReturnsEw: cast.potentialReturnsEw ?? null,
                              price: cast.price,
                              stakePerLine: cast.stakePerLine,
                              totalStake: cast.totalStake ?? null,
                              type: cast.type,
                          };

                const combinationModel: CombinationsType = {
                    ...simpleCombinationModel,
                    ...castCombinationModel,
                };

                tempMap.set(combination.type, combinationModel);
            }
        }

        return tempMap;
    }

    @computed public get castBets(): Array<BetResponseExtendedType> {
        if (this.corePossibleBetsResponse !== null) {
            const castBets: Array<BetResponseExtendedType> = [];
            for (const bet of this.corePossibleBetsResponse.bets) {
                if (this.configComponents.precision.newFromAnything(bet.stakePerLine).isGreaterThanZero()) {
                    // eslint-disable-next-line no-negated-condition
                    if (bet.type !== 'SGL') {
                        const combination = this.corePossibleBetsResponse.combinations[bet.type];
                        if (combination !== undefined) {
                            castBets.push({
                                ...bet,
                                numLines: combination.numLines,
                            });
                        }
                    } else {
                        castBets.push({
                            ...bet,
                            numLines: 1,
                        });
                    }
                }
            }

            return castBets;
        }
        return [];
    }

    @computed public get marketsWithRelatedSelections(): Array<TypeId.MarketId> {
        const helperArray: Array<TypeId.MarketId> = [];
        const tempSet: Set<TypeId.MarketId> = new Set();
        for (const leg of this.coreLegsPossibleBetsResponse) {
            const selectionId = parseInt(leg.id, 10);
            const selectionModel = isNaN(selectionId) ? null : this.sdkCustomer.models.getSelection(selectionId);
            if (selectionModel !== null) {
                if (helperArray.includes(selectionModel.marketId)) {
                    tempSet.add(selectionModel.marketId);
                }
                helperArray.push(selectionModel.marketId);
            }
        }
        return Array.from(tempSet);
    }

    @computed public get errors(): Array<ErrorRawType> {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.errors;
        }
        return [];
    }

    @computed public get bonusEngineFreeBetErrors(): Array<ErrorRawType> {
        if (this.corePossibleBetsResponse === null) {
            return [];
        }

        const seen = new Set();

        return this.corePossibleBetsResponse.errors
            .filter((error) => error.resource.includes('bonus-engine'))
            .filter((el) => {
                const duplicate = seen.has(el.debugDetails);

                seen.add(el.debugDetails);

                return !duplicate;
            });
    }

    @computed public get isSuccess(): boolean {
        if (this.corePossibleBetsResponse === null) {
            return false;
        }
        return this.corePossibleBetsResponse.errors.length === 0;
    }

    @computed public get playableBalanceAmounts(): PlayableBalanceAmountsType | null | undefined {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.playableBalanceAmounts ?? null;
        }
        return null;
    }

    @computed public get isSinglesOnly(): boolean {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.singlesOnly;
        }
        return false;
    }

    @computed public get isLoading(): boolean {
        const corePossibleBetsResponse = this.justPossibleBetsResponse.get();
        return corePossibleBetsResponse.type === 'loading';
    }

    @computed public get isRelated(): boolean {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.related;
        }
        return false;
    }

    @computed public get isRelatedOnAdd(): boolean {
        if (this.corePossibleBetsResponse !== null) {
            return this.corePossibleBetsResponse.relatedOnAdd;
        }
        return false;
    }

    // ------------------------------ new possible bets request

    @computed private get accountData(): AccountDataType {
        const accountData = this.getAccountData();
        const accountId = accountData.userId;

        return {
            currency: accountData.currency,
            country: accountData.country,
            ipUser: accountData.ipUser,
            id: accountId,
        };
    }
    @computed private get corePossibleBetsRequestNew(): GetPossibleBetsRequestNewType {
        return {
            channel: this.getChannel(),
            rabBets: this.rabState === null ? null : this.rabState.getBets(null),
            isFreeBet: false,
            isFreeBetTax: false,
            accountData: this.accountData,
            bets: this.betsForRequest,
            legCombinations: this.legCombinationsForRequest,
            // Convert Map to Object -> io-ts will not respect Map so we have to convert it to more usable form
            selectedBonusEngineFreeBets: Object.fromEntries(this.selectedBonuses),
        };
    }

    @computed public get betsForRequest(): Array<BetsForRequestType> {
        const legs = this.coreLegsPossibleBetsRequest;

        const combinations = this.betslipData.getCoreCombinationsPossibleBetsRequest();
        const bets: Array<BetsForRequestType> = [];
        const legCombinations = [];
        for (const leg of legs) {
            const selectionId = leg.selectionId;

            const selection = selectionId.getModel();
            const market = selectionId.getMarketId().getModel();

            if (selection !== null && market !== null) {
                const bet: BetsForRequestType = {
                    id: leg.selectionId.rawId,
                    type: 'SGL',
                    stakePerLine: leg.stakePerLine.currentStakeValue.value,
                    payout: null,
                    eachWay: market.eachWay === undefined ? Boolean(leg.eachWay) : Boolean(leg.eachWay),
                    legs: [
                        {
                            type: 'standard',
                            selection: { id: selectionId.toOldId() },
                            market: { id: selectionId.getMarketId().toOldId() },
                            event: { id: selectionId.getEventId().toOldId() },
                            price: selection.price ?? null,
                            priceType: selection.spOnly === true ? leg.priceType : leg.priceType,
                        },
                    ],
                    freeBets: this.selectedBonuses.get(leg.selectionId.rawId)?.map((value) => value.id),
                    freebetCredits: [], //toJS(leg.freebetCredits),
                    freebetRemarks: [], //toJS(leg.freebetRemarks),
                    ip: this.accountData.ipUser ?? '',
                    channel: this.getChannel(),
                    country: this.accountData.country,
                    currency: this.accountData.currency,
                    correlationId: uuid.v4(),
                    // price: leg.price ?? null,
                    // maxStake: leg.maxStake,
                    // potentialReturns: leg.potentialReturns,
                    // potentialReturnsEw: leg.potentialReturnsEw,
                    // totalStake: null,
                    // errors: []
                };
                const combination = {
                    type: 'standard',
                    selection: { id: selectionId.toOldId() },
                    market: { id: selectionId.getMarketId().toOldId() },
                    event: { id: selectionId.getEventId().toOldId() },
                    price: selection.price,
                    priceType: leg.priceType,
                };

                bets.push(bet);
                legCombinations.push(combination);
            }
        }

        for (const [betId, combination] of Object.entries(combinations)) {
            const bet: BetsForRequestType = {
                id: `all${betId}`,
                type: betId,
                freebetCredits: [], //toJS(combination.freebetCredits),
                freebetRemarks: [], //toJS(combination.freebetRemarks),
                stakePerLine: combination.stakePerLine,
                payout: null,
                eachWay: Boolean(combination.eachWay),
                legs: legCombinations,
                ip: this.accountData.ipUser ?? '',
                channel: this.getChannel(),
                country: this.accountData.country,
                currency: this.accountData.currency,
                correlationId: uuid.v4(),
                freeBets: this.selectedBonuses.get(betId)?.map((value) => value.id),
                // price: combination.price,
                // maxStake: combination.maxStake,
                // potentialReturns: combination.potentialReturns,
                // potentialReturnsEw: combination.potentialReturnsEw,
                // totalStake: null,
                // errors: []
            };

            bets.push(bet);
        }

        return bets;
    }

    @computed public get selectedBetsForRequest(): Array<SmallLegStrictWithEachWayType> {
        const selected = [];

        for (const bet of this.betsForRequest) {
            if (bet.type === 'SGL') {
                const leg = bet.legs.length > 0 ? bet.legs[0] : undefined;
                if (leg !== undefined) {
                    selected.push({
                        ...leg,
                        eachWay: bet.eachWay,
                    });
                }
            }
        }

        return selected;
    }

    @computed public get legCombinationsForRequest(): Array<LegCombinationsForRequestType> {
        const legCombinations = this.selectedBetsForRequest.map((leg) => {
            return {
                id: leg.selection !== undefined && leg.selection !== null ? leg.selection.id.toString() : null,
                legs: [leg],
                eachWay: leg.eachWay,
            };
        });

        legCombinations.push({
            id: 'combinations',
            legs: this.selectedBetsForRequest,
            eachWay: false,
        });

        return legCombinations;
    }
}
