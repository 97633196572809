import styled from "@emotion/styled";
import { RewardWalletIcon } from "src/domains/layouts/shared/assets/iconography/betslip/RewardWallet";
import { withConfig } from "src/withConfig";
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { Loader } from 'src/domains/layouts/webview/components/loader/Loader';

export const Overlay = withConfig(theme => styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${theme.star.zIndexGlobal.highLevel};
`);

export const Content = withConfig(theme => styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    color: ${theme.star.popup.txtColor};

    position: relative;
    background: white;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 0 15px 10px ${theme.star.popup.bgColorQuinary};
`);

export const BetList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    max-height: 160px;
    width: 100%;
    overflow-y: auto;
`;

export const BonusIcon = withConfig(theme => styled(RewardWalletIcon, { label: 'BonusIcon' })`
    fill: ${theme.star.popup.txtColorQuaternary};
    width: 48px;
`);

export const CloseButton = styled(Button, { label: 'CloseButton' })`
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: transparent;

    svg {
        width: 16px;
    }
`;

export const Header = styled.div``;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const Title = withConfig(theme => styled.span`
    font-size: ${theme.star.fontSize.xMedium};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const ActionButton = styled(Button, { label: 'ActionButton' })`
    width: 100%;
`;


export const BetContainer = styled.div`
    display: flex;
    align-items: start;
    gap: 8px;
    cursor: pointer;
`;

export const BetInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BetTitle = withConfig(theme => styled.div`
    font-size: ${theme.star.fontSize.xRegular};
    font-weight: ${theme.star.fontWeight.bold};
`);

export const BetExpiration = withConfig(theme => styled.div`
    font-size: ${theme.star.fontSize.small};
    font-weight: ${theme.star.fontWeight.regular};
`);

export const BonusPopupLoader = styled(Loader, { label: 'BonusPopupLoader' })`
    justify-content: center;
`;
