import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { Duration } from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(relativeTime);

export type TimezoneType = 'Europe/London'; // to be extended if there's a need

export class DataTimeDuration {
    private readonly duration: Duration;

    private constructor(timeInMs: number) {
        this.duration = dayjs.duration(timeInMs);
    }

    public static from(timeInMs: number): DataTimeDuration {
        return new DataTimeDuration(timeInMs);
    }

    /** https://day.js.org/docs/en/durations/seconds */
    public seconds(): number {
        return this.duration.seconds();
    }

    /** https://day.js.org/docs/en/durations/seconds */
    public asSeconds(): number {
        return this.duration.asSeconds();
    }

    public minutes(): number {
        return this.duration.minutes();
    }

    public hours(): number {
        return this.duration.hours();
    }

    public days(): number {
        return this.duration.days();
    }
}

export class DateTime {
    private readonly pointInTime: Dayjs;

    private constructor(pointInTime: Dayjs) {
        this.pointInTime = pointInTime;
    }

    /**************************
     * Global *
     *************************/
    /**
     * @returns current Date and Time
     * @example Fri, 29 Sep 2023 13:26:33 GMT
     */
    public static current(): DateTime {
        return new DateTime(dayjs());
    }

    /** @returns specific Date or Time */
    public static from(date?: string | number | Date): DateTime | null {
        if (date === undefined || date === '') {
            return DateTime.current();
        } else if (dayjs(date).isValid() === false) {
            console.error('Date format is wrong!');
            return null;
        }

        return new DateTime(dayjs(date));
    }

    /**************************
     * StartOf date and time *
     *************************/
    /**
     * @returns Cloned Date object and set it to the start of a unit of time.
     * @example Fri, 29 Sep 2023 13:29:00 GMT
     */
    public readonly startOfMinutes = (): DateTime => {
        return new DateTime(this.pointInTime.startOf('minutes'));
    };

    /**
     * @returns Cloned Date object and set it to the start of a unit of time.
     * @example Fri, 29 Sep 2023 13:00:00 GMT */
    public readonly startOfHours = (): DateTime => {
        return new DateTime(this.pointInTime.startOf('hours'));
    };

    /**
     * @returns Cloned Date object and set it to the start of a unit of time.
     * @example Fri, 29 Sep 2023 22:00:00 GMT
     * @example Fri, 29 Sep 2023 00:00:00 */
    public readonly startOfDays = (): DateTime => {
        return new DateTime(this.pointInTime.startOf('days'));
    };

    /**
     * @returns Cloned Date object and set it to the start of a unit of time. According to ISO 8601 - first day of week is always Monday.
     * @example Mon, 25 Sep 2023 00:00:00 */
    public readonly startOfIsoWeek = (): DateTime => {
        return new DateTime(this.pointInTime.startOf('isoWeek'));
    };

    /*** @returns Cloned Date object and set it to the start of a unit of time.
     * @example Thu, 31 Aug 2023 22:00:00 GMT
     * @example Thu, 01 Sep 2023 00:00:00
     */
    public readonly startOfMonths = (): DateTime => {
        return new DateTime(this.pointInTime.startOf('months'));
    };

    /************************
     * EndOf date and time *
     ***********************/
    public endOfDays(): DateTime {
        return new DateTime(this.pointInTime.endOf('days'));
    }

    public endOfIsoWeek(): DateTime {
        return new DateTime(this.pointInTime.endOf('isoWeek'));
    }

    public endOfMonths(): DateTime {
        return new DateTime(this.pointInTime.endOf('months'));
    }

    /************
     * Get date *
     ************/
    /** @returns milliseconds */
    public getMillisecond(): number {
        return this.pointInTime.millisecond();
    }

    /** @returns seconds */
    public getSecond(): number {
        return this.pointInTime.second();
    }

    /** @returns minutes */
    public getMinute(): number {
        return this.pointInTime.minute();
    }

    /** @returns hours */
    public getHour(): number {
        return this.pointInTime.hour();
    }

    /** @returns day of week */
    public getDayOfWeek(): number {
        return this.pointInTime.day();
    }

    /** @returns day of month */
    public getDayOfMonth(): number {
        return this.pointInTime.date();
    }

    /** @returns month */
    public getMonth(): number {
        return this.pointInTime.month();
    }

    /** @returns year */
    public getYear(): number {
        return this.pointInTime.year();
    }

    /**************************
     *  Setting date or time  *
     *************************/

    public setDayOfMonth(dayOfMonth: number): DateTime {
        return new DateTime(this.pointInTime.date(dayOfMonth));
    }

    public setMonth(month: number): DateTime {
        return new DateTime(this.pointInTime.month(month));
    }

    public setYear(year: number): DateTime {
        return new DateTime(this.pointInTime.year(year));
    }

    public setHours(hours: number): DateTime {
        return new DateTime(this.pointInTime.hour(hours));
    }

    public setMinutes(minutes: number): DateTime {
        return new DateTime(this.pointInTime.minute(minutes));
    }

    public setSeconds(seconds: number): DateTime {
        return new DateTime(this.pointInTime.second(seconds));
    }

    public setMilliseconds(miliseconds: number): DateTime {
        return new DateTime(this.pointInTime.millisecond(miliseconds));
    }

    public setTime(hours: number, minutes: number, seconds: number, milliseconds: number): DateTime {
        return new DateTime(this.pointInTime.hour(hours).minute(minutes).second(seconds).millisecond(milliseconds));
    }

    public setDate(day: number, month: number, year: number): DateTime {
        return new DateTime(this.pointInTime.date(day).month(month).year(year));
    }

    /**************************
     *  Adding date or time  *
     *************************/
    public addSeconds(seconds: number): DateTime {
        return new DateTime(this.pointInTime.add(seconds, 'seconds'));
    }

    public addMinutes(minutes: number): DateTime {
        return new DateTime(this.pointInTime.add(minutes, 'minutes'));
    }

    public addHours(hours: number): DateTime {
        return new DateTime(this.pointInTime.add(hours, 'hours'));
    }

    public addDays(days: number): DateTime {
        return new DateTime(this.pointInTime.add(days, 'days'));
    }

    public addWeeks(weeks: number): DateTime {
        return new DateTime(this.pointInTime.add(weeks, 'weeks'));
    }

    public addMonths(month: number): DateTime {
        return new DateTime(this.pointInTime.add(month, 'months'));
    }

    public addYears(year: number): DateTime {
        return new DateTime(this.pointInTime.add(year, 'years'));
    }

    /**************************
     * Subtract date or time *
     **************************/
    public subtractMinutes(minutes: number): DateTime {
        return new DateTime(this.pointInTime.subtract(minutes, 'minutes'));
    }

    public subtractHours(hours: number): DateTime {
        return new DateTime(this.pointInTime.subtract(hours, 'hours'));
    }

    public subtractDays(day: number): DateTime {
        return new DateTime(this.pointInTime.subtract(day, 'days'));
    }

    public subtractWeeks(week: number): DateTime {
        return new DateTime(this.pointInTime.subtract(week, 'weeks'));
    }

    public subtractMonths(month: number): DateTime {
        return new DateTime(this.pointInTime.subtract(month, 'months'));
    }

    public subtractYears(year: number): DateTime {
        return new DateTime(this.pointInTime.subtract(year, 'years'));
    }

    /************************
     * Format date or time *
     ***********************/
    public readonly format = (format?: string): string => {
        if (format === undefined) {
            return this.pointInTime.format();
        }

        return this.pointInTime.format(format);
    };

    public toISOString(): string {
        return this.pointInTime.toISOString();
    }

    public toString(): string {
        return this.pointInTime.toString();
    }

    /** https://day.js.org/docs/en/plugin/custom-parse-format */
    public static strictFormattedYearMonthDay(year: number, month: number, day: number, format: string): DateTime {
        const formattedDate = `${year}-${month}-${day}`;

        /* Strict mode don't alow to user 0 before the number in date.
        ex. 2023-8-10 correct
        ex. 2023-08-10 not correct */
        return new DateTime(dayjs(formattedDate, format, true));
    }

    /** @returns Time formated since the specified value. E.g. 10 days ago* */
    public fromNow(): string {
        return this.pointInTime.fromNow();
    }

    /**********************
     * Timestamp methods *
     **********************/
    /* Timestamp in milliseconds */
    public unixMs(): number {
        return this.pointInTime.valueOf();
    }

    /** Timestamp in seconds */
    public unixSeconds(): number {
        return this.pointInTime.unix();
    }

    /*************************
     * Timezone and locales *
     ************************/
    public utc(): DateTime {
        return new DateTime(this.pointInTime.utc());
    }

    public tz = (timezone: string): DateTime => {
        return new DateTime(this.pointInTime.tz(timezone));
    };

    public locale = (locale: string): DateTime => {
        return new DateTime(this.pointInTime.locale(locale));
    };

    public static guessUserTimezone(): string {
        return dayjs.tz.guess();
    }

    /***************
     * Comparison *
     **************/
    public isBefore(date: DateTime | string): boolean {
        const dateToCompare = typeof date === 'string' ? DateTime.from(date) : date;
        if (dateToCompare === null) {
            console.error('dataToCompare is wrong: ', date);
        }

        return this.pointInTime.isBefore(dateToCompare?.pointInTime);
    }

    public isBeforeInDay(date: DateTime): boolean {
        return this.pointInTime.isBefore(date.pointInTime, 'day');
    }

    public isSame(dateTime: DateTime): boolean {
        return this.pointInTime.isSame(dateTime.pointInTime);
    }

    public isSameDay(day: DateTime): boolean {
        return this.pointInTime.isSame(day.pointInTime, 'day');
    }

    public isBetween(start: string, end: string): boolean {
        if (start === '' || end === '') {
            console.warn('Both start date and end date must be provided.');
        }

        const startDateTime = DateTime.from(start);
        const endDateTime = DateTime.from(end);

        return this.pointInTime.isBetween(startDateTime?.pointInTime, endDateTime?.pointInTime);
    }

    public isAfter(date: DateTime | string): boolean {
        const dateToCompare = typeof date === 'string' ? DateTime.from(date) : date;

        return this.pointInTime.isAfter(dateToCompare?.pointInTime);
    }

    /*********
     * Diff *
     ********/
    public diffInMilliseconds(end: DateTime): number {
        return this.pointInTime.diff(end.pointInTime, 'milliseconds');
    }

    public diffInSeconds(end: DateTime): number {
        return this.pointInTime.diff(end.pointInTime, 'seconds');
    }

    public diffInMinutes(end: DateTime): number {
        return this.pointInTime.diff(end.pointInTime, 'minutes');
    }

    public diffInDays(end: DateTime): number {
        return this.pointInTime.diff(end.pointInTime, 'days');
    }

    /**********
     * Extras *
     **********/
    public clone(): DateTime {
        return new DateTime(this.pointInTime.clone());
    }

    public isValid(): boolean {
        return this.pointInTime.isValid();
    }
}
