import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    Overlay,
    Content,
    CloseButton,
    BetList,
    Header,
    Title,
    BonusIcon,
    TitleContainer,
    ActionButton,
    BetExpiration,
    BetTitle,
    BetInfo,
    BetContainer,
    BonusPopupLoader,
} from './BetslipBonusPopup.style';
import { CloseIcon } from 'src/domains/layouts/shared/assets/icons/CloseIcon';
import { BonusCheckbox } from 'src/domains/sportsbook/betting/ui/betSlip/betslipBonus/BonusCheckbox/BonusCheckBox';
import { useAppStateContext } from 'src/appState/AppState';
import { useCommon } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { SelectedBonusType } from 'src/domains/sportsbook/betting/state/BetSlipState';

export const BetslipBonusPopup = observer('BetslipBonusPopup', () => {
    const {
        appSportsBookState: { betSlipState },
    } = useAppStateContext();
    const common = useCommon();
    const { money } = BasicDataModel.get(common);

    const {
        isBonusPopupOpen,
        closeBonusPopup,
        availableBonusFreeBets,
        selectedBonuses,
        applyBonuses,
        selectionFreeBets,
    } = betSlipState;

    const initialState = selectionFreeBets === null ? [] : selectedBonuses.get(selectionFreeBets.id) ?? [];

    const [selectedModalBonuses, setSelectedModalBonuses] = useState<Array<SelectedBonusType>>(initialState);

    const toggleBonusSelection = ({ id, selectionId, currentAmount, rawAmount }: SelectedBonusType): void => {
        setSelectedModalBonuses((prev) => {
            const isSelected = prev.some((bonus) => bonus.id === id);

            if (isSelected) {
                return prev.filter((bonus) => bonus.id !== id);
            }

            return [...prev, { id, selectionId, currentAmount, rawAmount }];
        });
    };

    if (!isBonusPopupOpen) {
        return null;
    }

    return (
        <Overlay onClick={closeBonusPopup}>
            <Content onClick={(e): void => e.stopPropagation()}>
                <Header>
                    <TitleContainer>
                        <BonusIcon />
                        <Title>Available Free Bets</Title>
                    </TitleContainer>
                    <CloseButton
                        size='small'
                        onClick={closeBonusPopup}
                    >
                        <CloseIcon />
                    </CloseButton>
                </Header>

                <BetList>
                    {availableBonusFreeBets === null ? (
                        <BonusPopupLoader />
                    ) : (
                        availableBonusFreeBets.map(({ id, selectionId, label, expiry, currentAmount, rawAmount }) => {
                            const addedBonus = {
                                id,
                                selectionId,
                                currentAmount,
                                rawAmount,
                            };

                            const handleOnChange = (): void => toggleBonusSelection(addedBonus);

                            return (
                                <BetContainer
                                    onClick={handleOnChange}
                                    key={label}
                                >
                                    <BonusCheckbox
                                        isChecked={selectedModalBonuses.some(
                                            (bonus) => bonus.id === id && bonus.selectionId === selectionId
                                        )}
                                        handleOnChange={handleOnChange}
                                    />
                                    <BetInfo>
                                        <BetTitle>
                                            {money(currentAmount)} {label}
                                        </BetTitle>
                                        <BetExpiration>{expiry}</BetExpiration>
                                    </BetInfo>
                                </BetContainer>
                            );
                        })
                    )}
                </BetList>
                <ActionButton
                    size='medium'
                    onClick={(): void => applyBonuses(selectedModalBonuses)}
                >
                    Apply
                </ActionButton>
            </Content>
        </Overlay>
    );
});
