import { withConfig } from 'src/withConfig';
import styled from "@emotion/styled";
import { Button } from 'src/domains/layouts/webview/components/common/button/Button';
import { RewardIcon } from 'src/domains/layouts/shared/assets/icons/RewardIcon';
import { GiftIcon } from 'src/domains/layouts/shared/assets/icons/GiftIcon';

export const CreditsTabContainer = styled('div', { label: 'CreditsTabContainer' })`
    padding: 16px;
`;

export const CreditsCardContainer = styled('div', { label: 'CreditsCardContainer' })`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const CreditsTotalContainer = withConfig(theme => styled('div', { label: 'CreditsTotalContainer' })`
    margin: 24px 0 16px 0;
    display: flex;
    justify-content: space-between;
    font-size: ${theme.star.fontSize.xRegular};
    line-height: 1.4285;
`);

export const CreditsTotalValue = withConfig(theme => styled('div', { label: 'CreditsTotalValue' })`
    font-weight: ${theme.star.fontWeight.bold};
    display: flex;
    gap: 4px;
    align-items: center;
`);

export const CreditsCardHeader = withConfig(theme => styled('div', { label: 'CreditsCardHeader' })`
    font-size: ${theme.star.fontSize.xMedium};
    color: ${theme.star.card.txtColor};
    line-height: 1.4;
    font-weight: ${theme.star.fontWeight.bold};
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
`);

export const CreditsCardBadgeExpiryAt = withConfig(theme => styled('div', { label: 'CreditsCardBadgeExpiryAt' })`
    color: ${theme.star.card.txtColor};
    font-size: ${theme.star.fontSize.xSmall};
    font-weight: ${theme.star.fontWeight.regular};
    line-height: 1.2;
`);


export const CreditsCardSubHeader = withConfig(theme => styled('div', { label: 'CreditsCardSubHeader' })`
    font-size: ${theme.star.fontSize.xSmall};
    color: ${theme.star.card.txtColorSecondary};
    line-height: 1.4;
`);

export const CreditsCardConditionsContainer = styled('div', { label: 'CreditsCardConditionsContainer' })`
    display: flex;
    flex-direction: column;
`;

export const CreditsCardRow = withConfig(theme => styled('div', { label: 'CreditsCardRow' })`
    display: flex;
    align-items: center;
    padding: 4px 0;
    font-size: ${theme.star.fontSize.xSmall};
    line-height: 1.4;
`);

export const CreditsCardRowLabel = styled('div', { label: 'CreditsCardRowLabel' })`
    flex: 0 0 auto;
    width: 96px;
    text-transform: capitalize;
`;

export const CreditsCardRowValue = withConfig(theme => styled('div', { label: 'CreditsCardRowValue' })`
    font-weight: ${theme.star.fontWeight.bold};
`);

export const CreditsCardButton = styled(Button, { label: 'CreditsCardButton' })`
    width: 100%;
`;

export const GiftIconStyled = styled(GiftIcon, { label: 'GiftIconStyled' })`
    width: 16px;
    height: 16px;
`;

export const RewardIconStyled = styled(RewardIcon, { label: 'RewardIconStyled' })`
    width: 16px;
    height: 16px;
`;
