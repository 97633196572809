import { LinkItemLinkType } from 'src/domains/layouts/webview/components/navigationPanel/NavigationPanel';
import { TranslationsStore } from 'src/domains/layouts/state/translationsStore/TranslationsStore';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { Common } from 'src/domains/common/Common';
import { AutoWeakMap } from 'src_common/common/mobx-utils/AutoWeakMap';
import { SpecialSportsListState } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportListState';

export class PopularSportState {
    private readonly config: ConfigComponents;
    private readonly translationsStore: TranslationsStore;
    private readonly specialSportState: SpecialSportsListState;

    public static get = AutoWeakMap.create((common: Common) => new PopularSportState(common));
    private constructor(common: Common) {
        this.translationsStore = TranslationsStore.get(common);
        this.config = ConfigComponents.get(common);
        this.specialSportState = SpecialSportsListState.get(common);
    }

    public generateNavigationList = (sportsArray: Array<string>): LinkItemLinkType[] => {
        const { hiddenSportsList } = this.config.config;
        const out: LinkItemLinkType[] = [];
        sportsArray.forEach((singlePopularSport) => {
            if (hiddenSportsList.includes(singlePopularSport) === true) {
                return;
            }

            if (singlePopularSport === 'price-boost') {
                if (this.specialSportState.hasPricedBoost) {
                    out.push({
                        key: 'price-boost',
                        to: {
                            name: 'sport-special',
                            id: 'PriceBoosts',
                        },
                        label: 'PRICE BOOSTS',
                    });
                }
            } else if (singlePopularSport === 'horseracing' || singlePopularSport === 'greyhoundracing') {
                out.push({
                    key: singlePopularSport,
                    to: {
                        name: 'sport',
                        nameType: 'races',
                        id: singlePopularSport,
                        type: null,
                        event: null,
                    },
                    label: this.translationsStore.translateSport(singlePopularSport),
                });
            } else {
                out.push({
                    key: singlePopularSport,
                    to: {
                        name: 'sport',
                        nameType: 'regular',
                        id: singlePopularSport,
                    },
                    label: this.translationsStore.translateSport(singlePopularSport),
                });
            }
        });
        return out;
    };
}
