import { useAppStateContext } from 'src/appState/AppState';
import { Intercom, shutdown, update } from '@intercom/messenger-js-sdk';
import { useCommon } from 'src/domains/common/Common';
import { TrpcClient } from 'src/appState/TrpcClient';
import { useEffect } from 'react';
import { observer } from 'src/utils/mobx-react';
import { autorun } from 'mobx';
import { DateTime } from 'src_common/utils/time/time';

export const IntercomChatDefault = async (
    trpcClient: TrpcClient,
    userId: number,
    email: string | null | undefined,
    name: string | undefined,
    createdDate: string | undefined
): Promise<void> => {
    const userIdString = userId.toString();
    const userEmail = email === null || email === undefined ? '' : email;
    const createdAt = DateTime.from(createdDate)?.unixSeconds();
    const hashFromServer = await trpcClient.client.intercomHashRouter.getHash.mutate({
        userId: userIdString,
    });

    update({
        app_id: 'dm6a93vb',
        user_id: userIdString,
        name: name,
        email: userEmail,
        created_at: createdAt,
        user_hash: hashFromServer,
    });
};

export const IntercomChat = observer('IntercomChat', () => {
    const { appPlayersState } = useAppStateContext();
    const { accountState, usersState } = appPlayersState;
    const { trpcClient, session } = useCommon();

    const account = accountState.account?.basicDataReady;
    const createdAt = usersState.walletData.valueReady?.createdAt;
    useEffect((): (() => void) => {
        const dispose = autorun(async () => {
            if (session.isAuthorized && account !== null && account !== undefined) {
                try {
                    await IntercomChatDefault(trpcClient, account.id, account.email, account.name, createdAt);
                } catch (error) {
                    console.error('Error initializing Intercom chat:', error);
                }
            } else {
                shutdown();
                update({
                    app_id: 'dm6a93vb',
                });
            }
        });

        return dispose;
    }, [session.isAuthorized, account?.id, createdAt]);

    Intercom({
        app_id: 'dm6a93vb',
    });

    return null;
});
